<template>
<div>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Table</h1>
        <p>Explore the granual data for all policies.
        </p>
        <div class="my-4" style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="cprd" :rows="initiativesPolicies" v-model="rowsFiltered" :staticDataProperties="staticDataProperties"></filter-interface>
          <search-interface namespace="cprd" :rows="rowsFiltered" v-model="rows"></search-interface>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form inline class="mb-4">
          <b-form-group label="Optional table columns:">
            <b-form-checkbox-group
              id="checkbox-table-columns"
              v-model="columnsSelected"
              :options="columnsOptions"
            ></b-form-checkbox-group>
          </b-form-group>
          <b-btn class="filter-button ml-3 mr-3" @click="showTable1 = !showTable1">Flip columns</b-btn>
          <b-btn class="filter-button ml-3 mr-3" @click="excel" v-if="$config.BUILD === 'full'">Download</b-btn>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="showTable1">
      <b-col>
        <b-table responsive sticky-header="60vh" striped hover :items="table" :fields="fields" @row-clicked="openInitiative" style="cursor: pointer;"></b-table>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!showTable1">
      <b-col>
        <b-table responsive sticky-header="60vh" striped hover :items="table2" :fields="fields2" @row-clicked="openInitiative" style="cursor: pointer;"></b-table>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import zipcelx from 'zipcelx'

import FilterInterface from '@/components/FilterInterfaceV4.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
import policysets from '@/libs/policysets.js'

const standardFields = [
  {
    key: 'area',
    label: 'Area',
    sortable: 'true'
  },
  {
    key: 'policy_name',
    label: 'Policy',
    sortable: 'true'
  },
  {
    key: 'status',
    label: 'Status',
    sortable: 'true'
  },
  {
    key: 'field_label',
    label: 'Measure',
    sortable: 'true'
  }
]

export default {
  name: 'PoliciesTable',
  components: {
    FilterInterface,
    SearchInterface
  },
  computed: {
    countries: function () {
      // get countries from a country property of this.rows, sorth them alphabetically, and remove duplicates
      const countries = _.sortBy(_.uniq(_.map(this.rows, 'country')))
      return countries
    },
    columnsOptions: function () {
      const options = []
      _.each(standardFields, (field) => {
        options.push({ value: field.key, text: field.label })
      })
      return options
    },
    fields: function () {
      let fields = []
      _.each(standardFields, (field) => {
        if (this.columnsSelected.includes(field.key)) {
          fields.push(field)
        }
      })
      _.each(this.countries, (country) => {
        fields.push({
          key: country,
          label: country,
          sortable: 'true'
        })
      })
      return fields
    },
    fields2: function () {
      let fields = []
      _.each(standardFields, (field) => {
        if (this.columnsSelected.includes(field.key)) {
          fields.push(field)
        }
      })
      fields.push({
        key: 'country',
        label: 'Country',
        sortable: 'true'
      })
      fields.push({
        key: 'data',
        label: 'Data',
        sortable: 'true'
      })
      return fields
    },
    initiativesPolicies: function () {
      return this.$store.state.initiativesPolicies
    },
    policies: function () {
      let policies = []
      _.each(this.rows, (row) => {
        if (row.impactpolicy?.cpd) { // only include policies marked as cpd = true
          policies.push({
            id: row.impactpolicy_id,
            name: row.impactpolicy.name,
            area: row.impactpolicy.taxonomy[0]?.name,
            status: row.impactstatus.name
          })
        }
      })
      policies = _.uniqBy(policies, 'id')
      return policies
    },
    rows: {
      get () {
        return this.$store.state.initiativesPoliciesShow
      },
      set (payload) {
        this.$store.commit('setInitiativesPoliciesShow', payload)
      }
    },
    table2: function () {
      const table = []
      _.each(this.policies, (policy) => {
        const policyset = _.find(policysets, { 'id': policy.id })
        _.each(policyset.fields, (field) => {
          const rowStandard = {
            policy_id: policy.id,
            area: policy.area,
            policy_name: policy.name,
            status: policy.status,
            field_name: field.name,
            field_label: field.label
          }
          _.each(this.countries, (country) => {
            const row = _.clone(rowStandard)
            row.country = country
            row.data = 'No data'
            table.push(row)
          })
        })
      })
      _.each(table, (row) => {
        const policy = _.find(this.rows, { 'country': row.country, 'impactpolicy_id': row.policy_id })
        if (policy) {
          if (row.field_name in policy.policydata) {
            row.data = policy.policydata[row.field_name]
          }
        }
      })
      _.remove(table, (row) => {
        return row.data === 'No data'
      })
      return table
    },
    table: function () {
      const table = []
      _.each(this.policies, (policy) => {
        const policyset = _.find(policysets, { 'id': policy.id })
        _.each(policyset.fields, (field) => {
          const row = {
            policy_id: policy.id,
            area: policy.area,
            policy_name: policy.name,
            status: policy.status,
            field_name: field.name,
            field_label: field.label
          }
          _.each(this.countries, (country) => {
            row[country] = 'No data'
          })
          table.push(row)
        })
      })
      _.each(table, (row) => {
        _.each(this.countries, (country) => {
          const policy = _.find(this.rows, { 'country': country, 'impactpolicy_id': row.policy_id })
          if (policy) {
            if (row.field_name in policy.policydata) {
              row[country] = policy.policydata[row.field_name]
            }
          }
        })
      })
      return table
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      columnsSelected: standardFields.map(x => x.key),
      rowsFiltered: [],
      showTable1: true,
      staticDataProperties: [
        { name: 'Areas', property: 'area', graph: 'area', tree: true },
        { name: 'Countries', property: 'country', direct: true },
        { name: 'Policies', property: 'impactpolicy', graph: 'impactpolicy', tree: true },
        { name: 'Sectors', property: 'sectors', graph: 'sectors', sectors: true },
        { name: 'Status', property: 'impactstatus', graph: 'impactstatus', tree: true }
      ]
    }
  },
  created: async function () {
    document.title = "Climate Policy and Regulatory Dashboard"
  },
  methods: {
    excel: function () {
      this.$stat.log({ page: 'cpd table', action: 'download Excel' })
      const data = []
      let fields = []
      let dlSource = []
      if (this.showTable1) {
        fields = this.fields
        dlSource = this.table
      } else {
        fields = this.fields2
        dlSource = this.table2
      }
      /*
      const fields = [
        { field: 'id', name: 'initiative id' },
        { field: 'name', name: 'name' },
        { field: 'headline', name: 'headline' },
        { field: 'description', name: 'description' },
        { field: 'country', name: 'country' },
        { field: 'issued', name: 'issued' },
        { field: 'effective', name: 'effective' },
        { field: 'first_reporting', name: 'first reporting' },
        { field: 'entities', name: 'entities' },
        { field: 'impactexperts', name: 'experts' },
        { field: 'status', name: 'status' },
        { field: 'impacttopics', name: 'topics' },
        { field: 'regulators', name: 'regulators' },
        { field: 'report_type', name: 'report type' },
        { field: 'reportingframeworks', name: 'reporting frameworks' },
        { field: 'assurance', name: 'assurance required' },
        { field: 'comply_explain', name: 'comply or explain' },
        { field: 'mandatory', name: 'mandatory' },
        { field: 'optional', name: 'optional' },
        { field: 'workflow_created_at', name: 'workflow stage created at' },
        { field: 'workflow_created_by', name: 'workflow created by' },
        { field: 'workflow_id', name: 'workflow stage' },
        { field: 'created_at', name: 'created at' },
        { field: 'created_by', name: 'created by' },
        { field: 'updated_at', name: 'updated at' },
        { field: 'updated_by', name: 'updated by' }
      ]
      */
      const headings = _.map(fields, field => {
        return { value: field.label, type: 'string' }
      })
      data.push(headings)
      _.each(dlSource, s => {
        const row = _.map(fields, field => {
          return { value: s[field.key], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'CPD_Initiatives_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    openInitiative: function (initiative) {
      this.$router.push({ name: 'ImpactPoliciesPolicy', params: { id: initiative.policy_id } })
    }
  },
  watch: {
    columnsSelected: function () {
      console.log(this.columnsSelected)
    }
  }
}
</script>

<style>
</style>
